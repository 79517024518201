<script>
  import MainScreen from '@cox2m/city-services-ui-components/src/main/MainScreen.svelte';

  import { menuOpts, user } from '../stores';
</script>

<style>
  .vh-85{
    height: 85vh !important;
  }
</style>

<MainScreen title="Zensors Cameras" menuOpt={$menuOpts} appSlug="parking" showAlertsBanner={false}>
  <div slot='main-dashboard'>
    <iframe title="zensors cameras" src="https://zensors.live/widgets/98de73d1-6b28-44cf-9fc4-e42c1aee1f3d" class="w-100 vh-85 border-0" />
  </div>
</MainScreen>
