import AlertsWrapper from './forms/alerts/AlertsWrapper.svelte';
import Report from './forms/Report.svelte';

import NotFoundPage from './forms/NotFoundPage.svelte';

const routes = {
  '/overview': Report,
  '/alerts': AlertsWrapper,

  '*': NotFoundPage
};

export const openRoutes = []

export default routes
