export const staticMenuOptions = user => [
  {
    key: 'home',
    name: 'Home',
    icon: 'dashboard',
    path: 'SMART_CITIES_URL',
    externalRoute: true,
    shouldShow: true
  },
  {
    key: 'overview',
    path: '/overview',
    name: 'Overview',
    icon: 'monitor',
    shouldShow: true
  },
  {
    key: 'alerts',
    path: '/alerts',
    name: 'Alerts+Notifications',
    icon: 'gear-2',
    shouldShow:
      user &&
      user.operations &&
      user.operations.includes('get-notifications-metrics-by-app-id')
  },

  {
    key: 'logout',
    path: 'SMART_CITIES_URL/#/logout',
    name: 'Log out',
    icon: 'log-out',
    shouldShow: true,
    bottomOption: true,
    externalRoute: true
  }
];
